<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Dokter'">
          <template v-slot:body>
            <div>

              <Form
                :form="form"
                :route="'doctors'"
                :password="true"
              />

            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/doctors/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      form: {
        user_type_id: "1",
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        sip_number: "",
        sip_exp: new Date().toISOString().substr(0, 10),
        id_card_number: "",
        mobile_phone: "",
        phone: "",
        address: "",
        is_satusehat_skip: 0,
        polyclinic_id: null,
        photo: [],
        photo_name: "/images/default-profile.svg"
      }
    }
  },

  components: {
    Card,
    Form
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Daftar Dokter", route: "/doctor/list" },
      { title: "Tambah" },
    ])
  },

}

</script>